import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: "mdiSvg",
	},
	theme: {
		themes: {
			light: {
				primary: "CC8180",
				secondary: "A66F88",
				accent: "DDA886",
				anchor: "8791A0",
				error: "CE8671",
				info: "716F81",
				warning: "F1BC9A",
			},
		},
	},
});
