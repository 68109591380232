import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueKatex from "vue-katex";
import "katex/dist/katex.min.css";

Vue.config.productionTip = false;
Vue.use(VueKatex);

new Vue({
	router,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
