



























import { Component, Vue } from "vue-property-decorator";
import { mdiBook, mdiCircle, mdiMusic } from "@mdi/js";

@Component
export default class Home extends Vue {
	icons = { mdiBook, mdiCircle, mdiMusic };
}
