import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/theory",
		name: "Theory",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Theory.vue"),
	},
	{
		path: "/drawing",
		name: "Drawing",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Drawing.vue"),
	},
	{
		path: "/equalizer",
		name: "Equalizer",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Equalizer.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
